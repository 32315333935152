@import url('https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;800');
html {
	box-sizing: border-box;
	font-size: 10px;
}
*,
*:before,
*:after {
	box-sizing: inherit;
}
body {
	margin: 0;
	padding: 0;
	font-weight: 400;
	font-size: 1.6rem;
	font-family: 'Jost', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background: linear-gradient(
			270deg,
			rgba(178, 230, 212, 1) 0%,
			rgb(98, 140, 168) 100%
		)
		no-repeat fixed;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
